import { useMutation, useQuery, useQueryClient } from "@tanstack/vue-query";

export const useRequest = () => {
  const { trpcClient } = useTrpcClient();
  const queryClient = useQueryClient();

  return {
    useQuery,
    useMutation,
    trpcClient,
    queryClient,
  };
};
